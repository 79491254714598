import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
//import * as styles from "../styles/home.module.css"

import { Carousel, Container, Card, Button, Row, Col } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'

const IndexPage = ({data}) => (
  <Layout>
    <Container>
      <h2>Easy Umroh Tanur Muthmainnah</h2>
      <h3>Album foto perjalanan umroh tahun 2022</h3>

      <Carousel>
        {data.slideShow.edges.map(({node}) => (
          <Carousel.Item key={node.id}>
            <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base} />
          </Carousel.Item>
        ))}
      </Carousel>
          <Row>
            <Col>
            <br />
              <Card style={{ width: '25rem'}}>
                <Card.Body>
                  <Card.Title>
                    Alhamdulilah, Allah berikan kami kesempatan
                  </Card.Title>
                  <Card.Text>
                    Tiada lepas ucap syukur kami, perjalanan berjalan dengan lancar. Mulai take off jam 12:20 wib dan landing di bandara king abdul aziz jedah jam 17:28 kalau waktu Indonesia jam 21:28
                  </Card.Text>
                  <Button href='gallery/alhamdulillah-allah-berikan-kami-kesempatan' variant='primary'>Lanjutan Ceritanya</Button>
                </Card.Body>
              </Card>
            <br />
            </Col>
            
            <Col>
            <br />
              <Card style={{ width: '25rem'}}>
                <Card.Body>
                  <Card.Title>
                    Susahnya untuk suntik meningitis!
                  </Card.Title>
                  <Card.Text>
                    Waktu semakin mepet, tinggal kurang lebih 10 hari, kami mendapatkan informasi dari travel umroh tanur bahwa kami bisa berangkat,
                    Namun ternyata salah satu syaratnya harus suntik vaksin meningitis. Ya Allah ternyata tidak mudah ya...
                  </Card.Text>
                  <Button href='/gallery/suntik-meningitis-jakarta' variant='primary' >Lanjutan Ceritanya</Button>
                </Card.Body>
              </Card>
            <br />
            </Col>
            
            <Col>
              <br />
                <Card style={{ width: '25rem'}}>
                  <Card.Body>
                    <Card.Title>
                      Cerita Umroh Perdana 2022 #3
                    </Card.Title>
                    <Card.Text>
                      Bersama Suami saya berangkat Umroh di bulan oktober tahun 2022.
                    </Card.Text>
                    <Button href='/gallery/ambil-perlengkapan-umroh-di-tangsel/' variant='primary'>Lanjutan Ceritanya</Button>
                  </Card.Body>
                </Card>
               <br />    
            </Col>
          </Row>

          <Row>            
            <Col>
            <br />
              <Card style={{ width: '25rem'}}>
                <Card.Body>
                  <Card.Title>
                    Cerita Umroh Perdana 2022 part #22
                  </Card.Title>
                  <Card.Text>
                    Bersama Suami saya berangkat Umroh di bulan oktober tahun 2022.
                  </Card.Text>
                  <Button href='/gallery/suntik-meningitis-jakarta' variant='primary'>Lanjutan Ceritanya</Button>
                </Card.Body>
              </Card>
            <br />
            </Col>
            
            <Col>
              <br />
                <Card style={{ width: '25rem'}}>
                  <Card.Body>
                    <Card.Title>
                      Cerita Umroh Perdana 2022 #3
                    </Card.Title>
                    <Card.Text>
                      Bersama Suami saya berangkat Umroh di bulan oktober tahun 2022.
                    </Card.Text>
                    <Button href='/gallery/ambil-perlengkapan-umroh-di-tangsel/' variant='primary'>Lanjutan Ceritanya</Button>
                  </Card.Body>
                </Card>
               <br />    
            </Col>
            <Col>
              <br />
                <Card style={{ width: '25rem'}}>
                  <Card.Body>
                    <Card.Title>
                      Cerita Umroh Perdana 2022 #4
                    </Card.Title>
                    <Card.Text>
                      Bersama Suami saya berangkat Umroh di bulan oktober tahun 2022.
                    </Card.Text>
                    <Button href='/gallery/manasik-umroh-tangsel' variant='primary'>Lanjutan Ceritanya</Button>
                  </Card.Body>
                </Card>
               <br />    
            </Col>
          </Row>
   
    </Container>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
    query {
      slideShow: allFile(
        filter: {relativeDirectory: {eq: "slides"}}
        sort: {fields: base, order: ASC}
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(
                height: 1600
                width: 4600
                placeholder: BLURRED
                quality: 70
                blurredOptions: {width: 100}
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
            }
          }
        }
      }
    }
  `